import React from 'react';
import { Container } from 'react-bootstrap';
import NavigationBar from './NavigationBar'; // adjust the path as necessary
import './App.css';
import './CustomNavbar.css';

const PrivacyPolicy = () => {
  return (
    <>
      <NavigationBar shouldCheckAuthentication={false} />

      <Container className="mt-4 mb-5">
        <h2 className="text-center mb-4">Privacy Policy of MeetAria.AI</h2>

        <p>
          Welcome to MeetAria.AI and its tangential products and others dedicated to individual influencer clones. Regardless of the domain or product you are interacting with, your privacy remains a top priority for us. This Privacy Policy applies across all our platforms and products.
        </p>

        <h4 className="mt-3">Information We Collect</h4>
        <p>
          Across all MeetAria.AI and tangential products, we collect information when users sign up to chat with AI clones of influencers who have provided their consent for us to create AI versions of them.
        </p>

        <ul>
          <li>Conversations: We store user conversations to improve our product and tailor the user experience.</li>
          <li>Email and Other Data: We collect user emails and other pertinent data to facilitate and enhance our services.</li>
        </ul>

        <h4 className="mt-3">Use of APIs</h4>
        <p>
          Our platforms, including MeetAria.AI and its subsidiaries, integrate with third-party services. Sharing data with these third parties is crucial to offer an optimized and enriched user experience.
        </p>

        <h4 className="mt-3">Data Sharing and Storage</h4>
        <p>
          At MeetAria.AI, and across all our subsidiary products, we commit to safeguarding your privacy. We refrain from sharing user data with external entities, except where indispensable for the operation of our services via third-party APIs. You can be assured that your data is stored securely, in alignment with industry-leading standards and best practices.
        </p>

        <h4 className="mt-3">Your Rights</h4>
        <p>
          All users interacting with MeetAria.AI or any of its subsidiary platforms have the right to access, modify, or erase their data. Moreover, you have the right to object to or restrict the processing of your data. To exercise any of these rights, please contact us through our designated channels.
        </p>

        <h4 className="mt-3">Updates to This Privacy Policy</h4>
        <p>
          We may periodically refine our Privacy Policy. We urge users to frequently inspect this page to stay updated about any modifications. Changes to this Privacy Policy are binding when posted on this page.
        </p>

        <h4 className="mt-3">Contacting Us</h4>
        <p>
          For any queries, feedback, or concerns regarding our privacy procedures, please reach out to us at <a href="https://airtable.com/appG6ZFO2FYs9X6AH/shrjN9ImYf8ZCHe0x" target="_blank" rel="noopener noreferrer">Contact Form</a>
        </p>

        <h2 className="text-center mb-4 mt-5">Terms of Service for MeetAria.AI Chat Services</h2>

        <p>
        By using the AI chat services provided by MeetAria.AI, you agree to the following terms and conditions:
        </p>

        <h4 className="mt-3">Partnership Agreements</h4>
        <p>
        We maintain partnership agreements with various influencers. These agreements stipulate the terms of compensation with our influencer partners. As a user, it's essential to understand that our services are offered under these specific agreements.
        </p>

        <h4 className="mt-3">Service Availability</h4>
        <p>
        We reserve the right to discontinue or suspend any AI clone chat service at our sole discretion. This can be due to reasons including, but not limited to, the termination of rights to host an influencer's AI clone on our platform. Please note that any prior purchases related to discontinued services are non-refundable.
        </p>

        <h4 className="mt-3">AI Clone Representation Accuracy</h4>
        <p>
        Please note that the AI clones of real life individuals and influencers on our platform are not accurate representations of the real person's background, life history, thoughts and beliefs. Anything that their AI Clone says should be interpreted as a fictional representation.
        </p>

        <h4 className="mt-3">Limitation of Liability</h4>
        <p>
        Interactions with our AI chat services are at your own risk. MeetAria.AI is not responsible or liable for any physical or psychological harm, distress, or any other adverse effects that may arise from your interactions with our AI services.
        </p>

        <h4 className="mt-3">Purchase and Refund Policy</h4>
        <p>
        Upon purchasing chat tokens for our service, understand that refunds are not guaranteed. All refund requests are subject to our review, and granting a refund remains solely at our discretion.
        </p>

        <h4 className="mt-3">Ownership of Content</h4>
        <p>
          All content and materials available on MeetAria.AI, including but not limited to text, graphics, website name, code, images, and logos, are the intellectual property of MeetAria.AI and are protected by applicable copyright laws. Any inappropriate use, reproduction, distribution, or transmission of any content or materials on this site without the express written permission of MeetAria.AI is strictly prohibited and may result in legal action.
        </p>

        <p className="mt-4">
        We advise all users to read and understand these terms thoroughly before using our AI chat services. By continuing to use our platform, you signify your acceptance of these terms.
        </p>

      </Container>
    </>
  );
};

export default PrivacyPolicy;
