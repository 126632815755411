import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import NavigationBar from './NavigationBar';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [messageSent, setMessageSent] = useState(false);
    const flask_server = process.env.REACT_APP_BACKEND_URL;
    const cloneName = process.env.REACT_APP_CLONE_NAME;

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${flask_server}/forgot-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ cloneName, email })
            });

            if (response.ok) {
                setMessageSent(true);
            } else {
                console.error("Error sending reset link");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <>
            <NavigationBar shouldCheckAuthentication={false} />
            <Container className="text-center mt-4 mb-5">
                {!messageSent ? (
                    <>
                        <h2>Forgot Password</h2>
                        <hr className="w-50 mx-auto mb-4" />
                        <Form onSubmit={handleSubmit}>
                            <Form.Group>
                                <Form.Label style={{fontWeight: 'bold'}}>Email:</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Button type="submit" className="mt-3">Submit</Button>
                        </Form>
                    </>
                ) : (
                    <h3>Password reset instructions have been sent, check your email.</h3>
                )}
            </Container>
        </>
    );
};

export default ForgotPassword;
