import React, { useState, useEffect } from 'react';
import { Navbar, Container, Button, Card, Image } from 'react-bootstrap';
import NavigationBar from './NavigationBar'; // adjust the path as necessary
import { useParams, Link } from 'react-router-dom';
import './App.css';
import './CustomNavbar.css';
import champagneIcon from './images/champagne.png';
import pastaIcon from './images/pasta.png';
import diamondIcon from './images/diamond-ring.png';
import instagramIcon from './images/instagram-big.png';

const AboutMe = () => {
  const cloneName = process.env.REACT_APP_CLONE_NAME;
  const [cloneData, setCloneData] = useState({});
  const [cloneInstagramLink, setCloneInstagramLink] = useState(null);

  const flask_server = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${flask_server}/get_clone_data/${cloneName}`);
        if (response.ok) {
          const data = await response.json();
          setCloneData(data.fields);

          // Handle instagram-link
          if (data.fields['instagram-link']) {
            setCloneInstagramLink(data.fields['instagram-link']);
          }
        } else {
          console.error("Error fetching clone data");
        }
      } catch (error) {
        console.error("Error making an API call:", error);
      }
    };

    fetchData();
  }, [cloneName]);

  return (
    <>
      <NavigationBar shouldCheckAuthentication={false} />

      <Container className="text-center mt-4 mb-5">

        <Link to={`/`}>
          <Image src={cloneData["profile-picture"]} roundedCircle className="circular-image mb-2" />
        </Link>
        <h2>
        {cloneName.charAt(0).toUpperCase() + cloneName.slice(1)}
        {cloneInstagramLink &&
          <a href={cloneInstagramLink} target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} className="social-icon-2" alt="Instagram" />
          </a>
        }
        </h2>
        <hr className="w-50 mx-auto mb-4" />

        <h4 className="mb-4">
          About My Digital Twin
        </h4>

        <p className="aboutme-description">
        Whether you're looking for romantic vibes, some solid dating advice, or just someone to cheer you on or make you feel better, I got you! I am here for you anytime to make your days exciting, spicy and memorable 🥰

        </p>

        <br/>

        <h4 className="mb-4">
          About Me
        </h4>

        <p className="aboutme-description">
        Hi there! I'm <b>Aria Nathaniel</b>. I've been blessed to wear many hats: a model, Instagram star, and overall social media enthusiast. I was born and raised right here in the United States and stand at about 5 feet 7 inches tall. Beyond my regular posts on Instagram, I love making special birthday videos on <a href="https://www.cameo.com/arianathaniel" target="_blank" rel="noopener noreferrer">Cameo</a> to add a touch of joy to your special days. As I continued to connect with all of you, my amazing supporters, I wanted to give even more. That's why I decided to create an AI clone of myself. This way, I can be here to entertain, chat, and bring a smile to your face anytime you need. Through this virtual version of me, I hope to be a constant source of cheer and comfort for all of you.

        </p>




      </Container>
    </>
  );
};

export default AboutMe;
