import { Navbar, Container, Nav, Offcanvas, Modal, Form, Button, Spinner } from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';
import './App.css';
import ReCAPTCHA from "react-google-recaptcha";
import { GoogleLogin } from '@react-oauth/google';

function NavigationBar({ shouldCheckAuthentication = false }) {
    const [checkAuthenticationOverride, setCheckAuthenticationOverride] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [showSideBar, setShowSideBar] = useState(false);
    const [isSignupLoading, setIsSignupLoading] = useState(false);

    // Inside your component
    const recaptchaRef = useRef(null);

    const ModalViews = {
      SIGNUP: 'signup',
      LOGIN: 'login',
      SIGNUP_SUCCESS: 'signupSuccess'
    };

    const [modalView, setModalView] = useState(ModalViews.SIGNUP); // default to 'signup'


    const [email, setEmail] = useState("");  // State for email
    const [password, setPassword] = useState("");
    const flask_server = process.env.REACT_APP_BACKEND_URL;
    const clone_name =  process.env.REACT_APP_CLONE_NAME;

    // Use state to store error messages
    const [loginError, setLoginError] = useState("");
    const [signupError, setSignupError] = useState("");

    useEffect(() => {
        checkAuthentication();
    }, [shouldCheckAuthentication]);

    const checkAuthentication = async () => {
        const token = localStorage.getItem('jwt_token');

        if (token) {
            const response = await fetch(`${flask_server}/is_authenticated`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
            }
        } else {
            setIsAuthenticated(false);
        }
    };

    const handleLogout = async () => {
        localStorage.removeItem('jwt_token');
        setIsAuthenticated(false);
        window.location.reload();
    };

    const handleShowSideBar = () => setShowSideBar(true);
    const handleCloseSideBar = () => setShowSideBar(false);

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();

        const response = await fetch(`${flask_server}/verify_password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password })
        });

        const data = await response.json();
        if (response.ok) {
            localStorage.setItem('jwt_token', data.access_token);
            window.location.reload();
        } else {
            setLoginError(data.error || 'Incorrect password. Please try again.');
        }
    };

    const handleSignUpSubmit = async (e) => {
        e.preventDefault();
        setIsSignupLoading(true); // Start loading
        const captchaToken = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();

        const response = await fetch(`${flask_server}/create_user_account`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password, 'g-recaptcha-response': captchaToken})
        });

        const data = await response.json();
        if (response.ok) {
            localStorage.setItem('jwt_token', data.access_token);
            setEmail('');
            setPassword('');
            if (data.is_existing_user) {
              window.location.reload();
            } else {
              setModalView(ModalViews.SIGNUP_SUCCESS)
          }
        } else {
            setSignupError(data.error || 'Issue with signing up. Try again later');
        }

        setIsSignupLoading(false); // End loading
    };

    return (
        <>
            <Navbar className="custom-navbar" expand="lg">
                <Container>
                    <Navbar.Brand href="/" className="quicksand-bold">Meet Aria ❤️</Navbar.Brand>
                    <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleShowSideBar} className="always-show-toggle" />
                </Container>
            </Navbar>

            <Offcanvas show={showSideBar} onHide={handleCloseSideBar} placement="end" id="offcanvasNavbar">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Meet Aria</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="d-flex flex-column justify-content-between" style={{ height: '100%' }}>
                    <div>
                        <Nav className="flex-column">
                            <Nav.Link className="nav-link-bold" href={`/`}>
                                My Clone 👩
                            </Nav.Link>
                            <Nav.Link className="nav-link-bold" href={`/aboutme/${clone_name}`}>
                                About Me 😍
                            </Nav.Link>
                            {isAuthenticated && (
                                <>
                                    <Nav.Link className="nav-link-bold" href={`/showlove/${clone_name}`}>
                                        Show Love ❤️
                                    </Nav.Link>
                                    <Nav.Link className="nav-link-bold" href="/seetokens">
                                        Token History 📜
                                    </Nav.Link>
                                </>
                            )}
                            {isAuthenticated ? (
                                <Nav.Link className="nav-link-bold" onClick={handleLogout}>Logout</Nav.Link>
                            ) : (
                                <Nav.Link className="nav-link-bold" onClick={() => { setCheckAuthenticationOverride(true); handleCloseSideBar() }}>Login/Signup</Nav.Link>
                            )}
                        </Nav>
                    </div>
                    <div className="bottom-navbar-container"> {/* Container for the bottom links and copyright */}
                        <div className="d-flex justify-content-center mb-2">
                            <Nav.Link className="nav-link-bold text-muted bottom-navbar-link" href="/privacypolicy">Privacy Policy & Terms</Nav.Link>
                            <Nav.Link className="nav-link-bold text-muted bottom-navbar-link" href="https://airtable.com/appG6ZFO2FYs9X6AH/shrjN9ImYf8ZCHe0x">Contact Us</Nav.Link>
                        </div>
                        <div className="text-center text-muted">
                            © Copyright - MeetAria.AI 2023
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

            <Modal show={(isAuthenticated === false && shouldCheckAuthentication) || checkAuthenticationOverride} onHide={() => setCheckAuthenticationOverride(false)} keyboard={false} centered>
                <Modal.Header>
                <div>
                  <Modal.Title>
                    {modalView === ModalViews.LOGIN ? "Log In" :
                     modalView === ModalViews.SIGNUP ? "Join The Party 🎉" : "Signup Successful 🥳"}
                  </Modal.Title>
                  {modalView === ModalViews.SIGNUP && (
                    <p style={{color: '#88B7D5', fontSize: 'medium', marginTop: '0.2rem', marginBottom: '0.2rem'}}>Hey Hey, influencer Aria Nathaniel here! Join quick to talk to my digital twin!</p>
                  )}
                </div>
                </Modal.Header>
                <Modal.Body>

                  {modalView == ModalViews.SIGNUP && (
                    <>
                      <Form onSubmit={handleSignUpSubmit}>
                          <Form.Group>
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                  type="text"
                                  value={email}
                                  placeholder="Enter email"
                                  onChange={(e) => setEmail(e.target.value)}
                                  required
                              />
                          </Form.Group>
                          <Form.Group>
                              <Form.Label>Password</Form.Label>
                              <Form.Control
                                  type="password"
                                  value={password}
                                  placeholder="Enter password"
                                  onChange={(e) => setPassword(e.target.value)}
                                  required
                              />
                          </Form.Group>
                          <br/>

                          <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LeisX0oAAAAAG4gdVBt9BtOxdx6_eZDPl4sB3dO" // replace with your site key
                                size="invisible"
                            />

                          <Button type="submit" disabled={isSignupLoading}>
                            {isSignupLoading ? (
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    {' '}
                                    Signing up...
                                </>
                            ) : (
                                'Continue 👉'
                            )}
                        </Button>

                          {signupError && <p className="text-danger mt-2">{signupError}</p>}

                          <div className="mt-3 d-flex justify-content-center">
                              <GoogleLogin
                                  onSuccess={credentialResponse => {
                                      console.log(credentialResponse);
                                  }}
                                  onError={() => {
                                      console.log('Login Failed');
                                  }}
                                  ux_mode="redirect"
                                  style={{ width: 'auto', display: 'inline-block' }} // Adjusting width and display
                                  login_uri="https://meetaria.ai/.netlify/functions/google-auth-callback-aria"
                              />
                          </div>

                      </Form>
                      <div className="mt-3 text-center">
                          Already have an account? <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={() => {
                              setModalView(ModalViews.LOGIN)}
                            }>
                              Log in
                          </span>
                      </div>
                    </>
                  )}
                  {modalView == ModalViews.SIGNUP_SUCCESS && (
                    <>
                        <p>Aria's digital twin is ready to meet you! Tell her what you did today or how much you hate your boss... </p>
                        <br/>
                        <Button onClick={() => window.location.reload()}>Start Chatting</Button>
                    </>
                  )}
                  {modalView == ModalViews.LOGIN && (
                    <>
                    <Form onSubmit={handlePasswordSubmit}>
                        <Form.Group>
                          <Form.Label>Email:</Form.Label>
                          <Form.Control
                              type="text"
                              placeholder="Enter email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                          />
                        </Form.Group>
                        <Form.Group className="mb-1">
                            <Form.Label>Password:</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="password-input"
                                required
                            />
                        </Form.Group>

                        <div className="mb-4" style={{ marginTop: '0.2rem' }}> {/* Increase margin-bottom */}
                            <span
                                style={{
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                        fontSize: '0.9rem'  // Reduce the font size
                                }}
                                onClick={() => window.location.href="/forgotpassword"}>
                                Forgot your password?
                            </span>
                        </div>

                        <Button type="submit">Submit</Button>
                        {loginError && <p className="text-danger mt-2">{loginError}</p>}

                        <div className="mt-3 d-flex justify-content-center">
                            <GoogleLogin
                                onSuccess={credentialResponse => {
                                    console.log(credentialResponse);
                                }}
                                onError={() => {
                                    console.log('Login Failed');
                                }}
                                ux_mode="redirect"
                                style={{ width: 'auto', display: 'inline-block' }} // Adjusting width and display
                                login_uri="https://meetaria.ai/.netlify/functions/google-auth-callback-aria"
                            />
                        </div>
                    </Form>
                      <div className="mt-3 text-center">
                          Not a member yet? <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={() => {
                              setModalView(ModalViews.SIGNUP)
                          }}>
                              Sign up
                          </span>
                      </div>
                    </>
                  )}

                </Modal.Body>
            </Modal>

        </>
    );
}

export default NavigationBar;
