import React, { useState, useEffect, useRef, useCallback } from 'react';
import NavigationBar from './NavigationBar'; // adjust the path as necessary
import { Link } from 'react-router-dom';
import { Modal, Navbar, Nav, Container, Form, Button, InputGroup, Spinner, Row, Col, Tooltip, OverlayTrigger} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import instagramIcon from './images/instagram-big.png';
import microphoneIcon from './images/microphone8.png'; // path to the microphone icon
import pauseIcon from './images/pause.png'; // path to the pause icon
import inputSpeakerIcon from './images/phone-call.png';
import soundWaveIcon from './images/sound-waves.png';
import shashankBlur from './images/shashank-blur.png';
import ariaBlur from './images/aria-2-blur-extra.png';
import './CustomNavbar.css';
import './App.css';
import RecordRTC from "recordrtc";


const ClonePage = () => {

  const blurredImages = {
    shashank: shashankBlur,
    aria: ariaBlur,
  };

  const [shouldCheckAuthentication, setShouldCheckAuthentication] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const [showLowBatteryMode, setShowLowBatteryMode] = useState(false);
  const [showTokenLimitModal, setShowTokenLimitModal] = useState(false);

  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [cloneVoiceId, setCloneVoiceId] = useState('');
  const [cloneMouthClosed, setCloneMouthClosed] = useState('');
  const [cloneMouthOpen, setCloneMouthOpen] = useState('');
  const [cloneInstagramLink, setCloneInstagramLink] = useState('');

  const cloneName = process.env.REACT_APP_CLONE_NAME; // Get the cloneName from the URL path
  // Add two new states to keep track of the videos' readiness
  const [mouthClosedReady, setMouthClosedReady] = useState(false);
  const [mouthOpenReady, setMouthOpenReady] = useState(false);
  const mouthClosedRef = useRef(null);
  const mouthOpenRef = useRef(null);
  const audioUrl = useRef(null);
  const [tapToPlayAudio, setTapToPlayAudio] = useState(false);
  const [mouthClosedLoaded, setMouthClosedLoaded] = useState(false);

  // State variables for audio recording
  const [chatMode, setChatMode] = useState(true);
  const stopTimeoutRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const recorderRef = useRef(null);
  const streamRef = useRef(null); // New ref for the stream

  const [recordTimeout, setRecordTimeout] = useState(null);

 // Setting webpage background image on load
  const backgroundImageStyle = {
  backgroundImage: `url(${blurredImages[cloneName]})`
  };
  // check if page is laoding and in that case have loading... and background image
  const [isPageLoading, setIsPageLoading] = useState(true);

  // need to make the flask server an environment variable
  // const flask_server = "http://127.0.0.1:5000";
  const flask_server = process.env.REACT_APP_BACKEND_URL;

// setting background color
  useEffect(() => {
  document.body.style.backgroundColor = '#f9f7ff';
  return () => {
    document.body.style.backgroundColor = null;
  };
}, []);


// Check if user is on low-battery-mode
useEffect(() => {
  const checkAutoplay = async () => {
    try {
      await mouthClosedRef.current.play();
      // If we reach this point, autoplay is possible.
    } catch (err) {
      // Autoplay is not possible.
      setShowLowBatteryMode(true);
    }
  };
  if (mouthClosedRef.current.readyState >= 3) {
    // If the video is already loaded, we can check autoplay immediately.
    checkAutoplay();
  } else {
    // If the video is still loading, we should wait until it's ready.
    mouthClosedRef.current.addEventListener('canplay', checkAutoplay);
  }
  // Don't forget to clean up the event listener if the component is unmounted while the video is still loading.
  return () => {
    if (mouthClosedRef.current) {
      mouthClosedRef.current.removeEventListener('canplay', checkAutoplay);
    }
  };
}, []);


  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }

    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
 }, []); // Empty array ensures that effect is only run on mount and unmount

 const placeholderText = isMobile ? 'Type to tell me how you feel...' : 'Type to say something or tell me how you feel...';

  useEffect(() => {
    const fetchData = async () => {
      try {

        const response = await fetch(`${flask_server}/get_clone_data/${cloneName}`);
        if (response.ok) {

          const data = await response.json();
          setCloneVoiceId(data.fields['voice-id']);
          setCloneMouthClosed(data.fields['mouth-closed']);
          setCloneMouthOpen(data.fields['mouth-open']);
          if (data.fields['instagram-link']) {
            setCloneInstagramLink(data.fields['instagram-link']);
          }

        } else if (response.status === 404) {
          // Handle the case when no record is found with the provided clone_name
          console.error("No record found with the provided clone_name.");
        } else {
          // Handle other error cases
          const errorData = await response.json();
          console.error("Error fetching data from the server:", errorData.error);
        }
      } catch (error) {
        console.error("Error making an API call:", error);
        setCloneVoiceId("DbjSLY2XXrfLj8pkVT1x");
        setCloneMouthClosed("https://firebasestorage.googleapis.com/v0/b/meetclone-cbf5c.appspot.com/o/aria-mouth-closed-2.mp4?alt=media&token=b2371036-1423-4218-91ca-465709ff40a7");
        setCloneMouthOpen("https://firebasestorage.googleapis.com/v0/b/meetclone-cbf5c.appspot.com/o/aria-mouth-open-2.mp4?alt=media&token=2f62cc05-17a7-4c79-b136-7283003f70dc");
      }
    };

    fetchData();
  }, [cloneName]);


  useEffect(() => {
    const startStream = async () => {
      if (!streamRef.current){
        streamRef.current = await navigator.mediaDevices.getUserMedia({ audio: true });
      }
    };

    if (!chatMode){
      startStream();
    }

  }, [chatMode]);

  const handleStartRecording = async () => {
    try {
      if (!streamRef.current){
        streamRef.current = await navigator.mediaDevices.getUserMedia({ audio: true });
      }
      recorderRef.current = RecordRTC(streamRef.current, {
        type: 'audio',
        numberOfAudioChannels: 2,  // Change to desired number of channels
        sampleRate: 48000,  // Change to desired sample rate
      });
      recorderRef.current.startRecording();
      setIsRecording(true);

      // Set a timer to stop recording after 2 minutes
      const timeout = setTimeout(() => {
        handleStopRecording();
      }, 120000); // 120000 milliseconds = 2 minutes

      setRecordTimeout(timeout);
    } catch (err) {
      console.error(err);
    }
  };

  const handleStopRecording = () => {

    // Clear the timeout if it's still active
    if (recordTimeout) {
      clearTimeout(recordTimeout);
      setRecordTimeout(null);
    }

    setIsLoading(true);
    setIsRecording(false);
    recorderRef.current.stopRecording(async () => {
      const blob = recorderRef.current.getBlob();
      const audioFile = new File([blob], "sample.wav", { type: "audio/wav" });

      // Cleaning up all streams
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
        streamRef.current = null;
      }

      await handleSubmit(audioFile, true);

      if (recorderRef.current && recorderRef.current.stream) {
        recorderRef.current.stream.getTracks().forEach(track => track.stop());
      }
      recorderRef.current = null;
    });
  };


  const getSpeechToText = async (audioFile) => {
    const formData = new FormData();
    formData.append('file', audioFile);
    const token = localStorage.getItem('jwt_token')

    const response = await fetch(`${flask_server}/transcribe`, {
      method: 'POST',
      body: formData,
      headers: {
          'Authorization': `Bearer ${token}`
      }
    });

    let responseData = null;
    if (response.ok) {
      responseData = await response.json();
      if (responseData.speech_to_text == undefined){
        console.error(`Error: No transcription returned`);
      }
    } else {
      console.error(`Error: ${response.status}`);
      if (response.status === 401 || response.status === 422) {
        setShouldCheckAuthentication(true);
      }
    }

    return responseData.speech_to_text;
  };


  const fetchTextResponse = async (text) => {
    const apiUrl = `${flask_server}/get_text_response_to_user`;
    const token = localStorage.getItem('jwt_token');

    try {

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          user_input: text,
          clone_name: cloneName,
          clone_voice_id: cloneVoiceId,
        }),

      });

      const data = await response.json();

      if (response.ok) {
        return data.response;
      } else if (response.status === 400 && data.token_limit_error) {
        setShowTokenLimitModal(true);
        return null;
      } else {
        if (response.status === 401 || response.status === 422) {
          setShouldCheckAuthentication(true);
        }
        console.error('Unexpected response status:', response.status);
        return "Uh oh... so something went wrong in the program, please try saying that again... or say something else";
      }
    } catch (error) {
      console.error('Text Response API call failed:', error);
      return "Uh oh... so something went wrong in the program, please try saying that again...";
    }
  };

  const fetchAudio = async (text_response) => {
    const apiUrl = `${flask_server}/get_voice_response_to_user`;
    const token = localStorage.getItem('jwt_token');

    try {

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Accept': 'audio/mpeg',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          text_response: text_response,
          clone_voice_id: cloneVoiceId,
        })
      });

      if (response.ok) {
        const arrayBuffer = await response.arrayBuffer();
        const blob = new Blob([arrayBuffer], { type: 'audio/mpeg' });
        const url = URL.createObjectURL(blob);
        return url;
      } else {
        if (response.status === 401 || response.status === 422) {
          setShouldCheckAuthentication(true);
        }
        console.error('Unexpected response status:', response.status);
        return null;
      }
    } catch (error) {
      console.error('Voice Response API call failed:', error);
      return null;
    }
  };

  const playAudioAndAnimate = async () => {


    mouthOpenRef.current.style.display = 'block';
    mouthClosedRef.current.style.display = 'none';

    setInputValue('');

    const audio = new Audio(audioUrl.current);
    audio.volume = 1.0; // Set volume to maximum
    audio.play();

    audio.addEventListener('ended', () => {
        // Starting the stream again
        const startStream = async () => {
            if (!streamRef.current) {
                try {
                    streamRef.current = await navigator.mediaDevices.getUserMedia({ audio: true });
                } catch (err) {
                    console.error(err);
                }
            }
        };

        if (!chatMode){
          startStream();
        }

        mouthClosedRef.current.style.display = 'block';
        mouthOpenRef.current.style.display = 'none';
        URL.revokeObjectURL(audioUrl.current);
    });

  }

  const handleSubmit = async (userVoiceFile, is_voice_message) => {
      setIsLoading(true);

      const start_time = new Date().getTime()

      let textResponse = "";

      try {
          if (is_voice_message){
            let res = await getSpeechToText(userVoiceFile);
            if (res){
              textResponse = await fetchTextResponse(res);
            } else {
              textResponse = "Hey, I'm sorry, I couldn't hear you. Could you check if your microphone is working or speak louder?";
            }
          } else {
            textResponse = await fetchTextResponse(inputValue);
          }
      } catch (error){
        textResponse = "Hey, I'm so sorry, but something went wrong. Try saying that again.";
      }

      if (textResponse) {
        const newAudioUrl = await fetchAudio(textResponse);

        if (newAudioUrl) {
          // updating the audio url
          audioUrl.current = newAudioUrl;
          // getting endtime at this point
          const end_time = new Date().getTime()

          if (isMobile || isSafari) {
            if(isPageLoading){
              setIsPageLoading(false);
            }
            setTapToPlayAudio(true);
          } else {
            playAudioAndAnimate();
          }

        }
      } else {
        console.error('Text Response API call failed or returned an empty response');
      }
      setIsLoading(false);
    };

  const handleVideoCanPlay = (videoRef, setVideoReady) => {
    if (videoRef.current && !videoRef.current.paused) {
      setVideoReady(true);

        // If it's the mouth-closed video and it's not already loaded, update its loaded state.
        if (videoRef === mouthClosedRef && !mouthClosedLoaded) {
          setMouthClosedLoaded(true);
        }
    }
  };

  return (
    <>

      <Modal show={showTokenLimitModal} onHide={() => setShowTokenLimitModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Token Limit Reached ⚡❤️</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Looks like we've been having a blast! I got an idea babe <br/>
          <a href={`/showlove/${cloneName}`}> Receive more tokens here </a>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowTokenLimitModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLowBatteryMode} onHide={() => setShowLowBatteryMode(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Low Power Mode Detected</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please turn off your phone battery's low power mode in iphone/android settings so that we can give you the most fun experience and load the clone's face automatically.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLowBatteryMode(false)}>
            I Understand
          </Button>
        </Modal.Footer>
      </Modal>

      <NavigationBar shouldCheckAuthentication={shouldCheckAuthentication} />

      <div className="video-container">

        {isPageLoading && (
          <>
            <div className="default-videoplaceholder-message">
              Loading
              <span className="dot1">.</span>
              <span className="dot2">.</span>
              <span className="dot3">.</span>
            </div>
          </>
        )}
        <div className="page-loading-background-image" style={backgroundImageStyle}></div>


        <video
          ref={mouthClosedRef}
          id="mouth-closed"
          src={cloneMouthClosed}
          autoPlay
          loop
          muted
          playsInline
          onCanPlay={() => handleVideoCanPlay(mouthClosedRef, setMouthClosedReady)}
        />
        <video
          ref={mouthOpenRef}
          id="mouth-open"
          src={cloneMouthOpen}
          autoPlay
          loop
          muted
          playsInline
          onCanPlay={() => handleVideoCanPlay(mouthOpenRef, setMouthOpenReady)}
        />
        <div className="name-box"> {/* Move the name box outside of the video elements */}
          {cloneName.charAt(0).toUpperCase() + cloneName.slice(1)}
          {cloneInstagramLink && (
            <a href={cloneInstagramLink} target="_blank" rel="noopener noreferrer">
              <img src={instagramIcon} className="social-icon" alt="Instagram" />
            </a>
          )}
        </div>


        {
       /*
       <div className="showlove-box">
          <Link to={`/showlove/${cloneName}`}>
            <Button className="buy-me-coffee ml-auto" variant="dark">
              Give Love ❤️
            </Button>
          </Link>
        </div>
        */
      }

    </div>

    {!chatMode ? (
      <Container className="mt-2 mb-4">
        {tapToPlayAudio ? (
          <div
            onClick={() => {
              playAudioAndAnimate();
              setTapToPlayAudio(false);
            }}
            style={{
              textAlign: 'center',
              top: '20px',
              color: '#FF85C2',
              fontWeight: 'bold',
              fontFamily: 'Quicksand',
              fontSize: '1em', // adjust size as needed
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            {
              (!isMobile && isSafari)
                ? "❤️ Tap here to play voice message (Safari browser limitation, use Chrome instead) ❤️"
                : "❤️ Tap here to play voice message ❤️"
            }
            {
              (!isMobile && isSafari)
                ? null
                : <img src={soundWaveIcon} alt="soundwaves" className="soundwaves-icon"/>
            }
          </div>
        ) : isLoading ? (
          <p className="thinking-text-style">
            Thinking
            <span className="dot1">.</span>
            <span className="dot2">.</span>
            <span className="dot3">.</span>
          </p>
        ) : (
          <div style={{ position: 'relative' }}>
            {!chatMode && (
              <div className="custom-tooltip">
                {isRecording ? "Recording... Tap to Stop" : "Tap once to speak to me!"}
              </div>
            )}
            <Button
              onClick={isRecording ? handleStopRecording : handleStartRecording}
              style={{backgroundColor: 'transparent', border: 'none', marginBottom: "-20px"}}
            >
              <img className="speaker-icon" src={isRecording ? pauseIcon : microphoneIcon} alt="record" />
            </Button>
          </div>
        )}
        {!(tapToPlayAudio || isLoading) && (
          <p className="switch-chat-mode" onClick={() => setChatMode(true)}>Switch to chat mode</p>
        )}
      </Container>
    ) : (
      <Container className="mt-2 mb-4">
        <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(null, false); }}>
          <InputGroup className={isMobile ? "flex-column align-items-center" : ""}>
            {tapToPlayAudio ? (
              <div
                onClick={() => {
                  playAudioAndAnimate();
                  setTapToPlayAudio(false);
                }}
                style={{
                  textAlign: 'center',
                  top: '20px',
                  color: '#FF85C2',
                  fontWeight: 'bold',
                  fontFamily: 'Quicksand',
                  fontSize: '1em', // adjust size as needed
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                  ❤️ Tap here to play voice message ❤️
                <img src={soundWaveIcon} alt="soundwaves" className="soundwaves-icon"/>
              </div>
            ) : <>
              {isMobile ? null : (
                <InputGroup.Text
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    color: '#FF85C2',
                    fontFamily: 'Quicksand',
                    fontWeight: 'bold',
                    fontSize: '1.1rem', // Adjust this value to your desired size
                  }}
                >
                  Chat:
                </InputGroup.Text>
              )}

              <Form.Control
                className={` ${isMobile ? "mobile-input" : "custom-input"}`}
                placeholder={placeholderText}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                style={isMobile ? {width: '100%'} : {}}
              />

                <Button variant="primary" type="submit" disabled={isLoading} style={isMobile ? {marginTop: '10px'} : {}}>
                  {isLoading ? (
                    <>
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                      {' '}
                      Thinking...
                    </>
                  ) : (
                    'Make Me Speak! 😃'
                  )}
                </Button>

                <Button variant="outline-secondary" className="speaker-switch-button" onClick={() => setChatMode(false)}>
                  <img className="input-speaker-icon" src={inputSpeakerIcon} alt="microphone" />
                </Button>

            </>}
          </InputGroup>
        </Form>
      </Container>
    )}

    </>
  );
};

export default ClonePage;
