import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './HomePage';
import ClonePage from './ClonePage';
import ShowLovePage from './ShowLovePage';
import MobileTest from './MobileTest';
import AudioStreamer from './AudioStream';
import AudioTranscribe from './AudioTranscribe';
import ClonePageAudio from './ClonePageAudio';
import ClonePageCombined from './ClonePageCombined';
import PaymentStatusPage from './PaymentStatusPage';
import ShowChatTokens from './ShowChatTokens';
import AboutMe from './AboutMe';
import PrivacyPolicy from './PrivacyPolicy';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import AuthLogin from './AuthLogin';


function App() {
  return (
    <GoogleOAuthProvider clientId="404691071964-uf38f8qt1mh4jvpqcidinodrt0aud7o6.apps.googleusercontent.com">
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<ClonePage />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/showlove/:cloneName" element={<ShowLovePage />} />
            <Route path="/paymentstatus/:clone_name/:token_count/:success" element={<PaymentStatusPage />} />
            <Route path="/seetokens" element={<ShowChatTokens />} />
            <Route path="/aboutme/:cloneName" element={<AboutMe />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/authlogin" element={<AuthLogin />} />
          </Routes>
        </div>
      </Router>
     </GoogleOAuthProvider>
  );
}

export default App;
