import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap'; // If you still want to use other bootstrap components

const AuthLogin = () => {
  const [error, setError] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const jwtToken = urlParams.get('access_token');
    const errorMessage = urlParams.get('error');

    if (jwtToken) {
      localStorage.setItem('jwt_token', jwtToken);
      window.location.href = '/'; // Redirect since we don't have useHistory
    } else if (errorMessage) {
      setError(errorMessage);
    } else {
      setError("Unexpected error during authentication.");
      console.error("Unexpected error during authentication.");
    }
  }, []);

  if (error) {
    return (
      <div>
        <p>{error}</p>
        <Link to="/" className="btn btn-primary">Return to Home</Link> {/* Using Bootstrap classes to style the Link as a button */}
      </div>
    );
  }

  return (
    <div style={{ color: '#ffa9d5' }}>
      Logging in...
    </div>
  );
}


export default AuthLogin;
