import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import NavigationBar from './NavigationBar';
import { useLocation, Link } from 'react-router-dom';

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const flask_server = process.env.REACT_APP_BACKEND_URL;

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };

    let query = useQuery();
    const token = query.get('token');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${flask_server}/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ token, newPassword })
            });

            if (response.ok) {
                setMessage('Password reset was successful. You can now login.');
            } else {
                setMessage('Error resetting password. Try again later.');
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <>
            <NavigationBar shouldCheckAuthentication={false} />
            <Container className="text-center mt-4 mb-5">
                <h2>Reset Password</h2>
                <hr className="w-50 mx-auto mb-4" />
                <Form onSubmit={handleSubmit}>
                    <Form.Group>

                        <Form.Label style={{fontWeight: 'bold'}}>New Password:</Form.Label>  {/* Added the 'font-weight-bold' class */}
                        <Form.Control
                            type="password"
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Button type="submit" className="mt-3">Reset Password</Button>
                </Form>
                {message && <p className="mt-4">{message}</p>}
            </Container>
        </>
    );
};

export default ResetPassword;
